import React from "react"
// import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"

// import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Footer from "../components/Footer/index"
import PrincipaisParceiros from "../components/PrincipaisParceiros/index"
import ContatoCards from "../components/ContatoCards/index"

import { FiArrowRight } from "react-icons/fi"

import GomasImage from "../components/Index/GomasImage"
import BalasImage from "../components/Index/BalasImage"
import BebidasImage from "../components/Index/BebidaImage"

import { Container } from "../styles/global"

import {
  HeroSection,
  ProdutosSection,
  ContatoSection,
  Bebida,
  Gomas,
  Balas,
} from "../styles/pages/index"

import heroImage from "../images/Index/heroImage.svg"

const IndexPage = () => (
  <>
    <SEO title="Delícias naturais à base de gengibre" />
    <Header />
    <StyledContainer>
      <HeroSection>
        <div className="image">
          <img src={heroImage} alt="Hero"></img>
        </div>
        <div className="text">
          <h1>Dezenas de produtos naturais à base de gengibre.</h1>
          <p>
            Sabor e saúde são prioridades na fabricação das gomas, bebidas,
            balas e cristais.
          </p>
          <AniLink cover direction="right" bg="#D1FED1" to="/saiba-mais">
            SAIBA MAIS
          </AniLink>
          <AniLink
            cover
            direction="right"
            bg="#D1FED1"
            to="/seja-parceiro"
            className="button"
          >
            SEJA PARCEIRO
          </AniLink>
        </div>
      </HeroSection>
      <ProdutosSection>
        <div className="metade">
          <AniLink
            cover
            direction="right"
            bg="#D1FED1"
            to="/produtos/bebida-gaseificada-a-base-de-gengibre"
          >
            <Bebida>
              <div className="text">
                <h1>Bebida a base de gengibre</h1>
                <p>
                  #EU
                  <span role="img" aria-label="AMO">
                    💜
                  </span>
                  ARDRAK
                </p>
              </div>
              <div className="image">
                <BebidasImage />
              </div>
            </Bebida>
          </AniLink>
          <AniLink
            cover
            direction="right"
            bg="#D1FED1"
            to="/produtos/gomas-de-gengibre"
          >
            <Gomas>
              <div className="image">
                <GomasImage />
              </div>
              <div className="text">
                <h1>Gomas de gengibre</h1>
                <p>
                  #EU
                  <span role="img" aria-label="AMO">
                    💚
                  </span>
                  ARDRAK
                </p>
              </div>
            </Gomas>
          </AniLink>
        </div>
        <AniLink to="/produtos/balas-macias-de-gengibre">
          <Balas>
            <div className="text">
              <h1>Balas macias de gengibre</h1>
              <div className="link">
                <AniLink cover direction="right" bg="#D1FED1" to="/produtos">
                  TODOS OS PRODUTOS
                </AniLink>
                <FiArrowRight size={21} />
              </div>
            </div>
            <div className="image">
              <BalasImage />
            </div>
          </Balas>
        </AniLink>
      </ProdutosSection>
      <PrincipaisParceiros />
      <ContatoSection>
        <h1>Fale conosco</h1>
        <p>
          Precisa de ajuda ou tem alguma sugestão? <br /> Escolha um dos itens
          abaixo e entre em contato.
        </p>
        <ContatoCards />
      </ContatoSection>
    </StyledContainer>
    <Footer />
  </>
)

const StyledContainer = styled(Container)`
  @media (max-width: 1240px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 354px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export default IndexPage
