import styled from "styled-components"
import colors from "../colors"

export const HeroSection = styled.section`
  position: relative;
  height: 600px;

  .image {
    position: absolute;
    right: 0;
    top: 0;
  }
  .text {
    z-index: 100;
    position: absolute;
    max-width: 414px;
    margin-top: 40px;
    h1 {
      padding-bottom: 25px;
      color: ${colors.VERDE_ESCURO};
      font-size: 36px;
      font-weight: 500;
    }
    p {
      padding-bottom: 25px;
      font-size: 22px;
      line-height: 28px;
      color: ${colors.CINZA_PADRAO};
    }
    a {
      display: inline-block;
      margin-bottom: 10px;

      font-size: 20px;
      font-weight: 500;
      color: ${colors.VERDE};
      text-decoration: none;
      &:hover {
        color: ${colors.VERDE_ESCURO};
      }
    }
    .button {
      margin-left: 20px;
      padding: 12px 30px 8px 30px;
      background-color: ${colors.VERDE_CLARO};
      border-radius: 999px;
      &:hover {
        background-color: ${colors.VERDE};
        color: white;
      }
    }
  }

  @media (max-width: 860px) {
    margin-bottom: 100px;
    height: auto;

    .image {
      position: relative;
      display: flex;
      justify-content: center;

      img {
        width: 673px;
      }
    }
    .text {
      position: relative;
      margin-bottom: 40px;
    }
  }
  @media (max-width: 400px) {
    .text {
      a {
        font-size: 18px;
      }
      .button {
        padding: 12px 25px 8px 25px;
      }
    }
  }
  @media (max-width: 330px) {
    .text {
      .button {
        margin-left: 10px;
      }
    }
  }
`

export const ProdutosSection = styled.section`
  a {
    text-decoration: none;
  }
  .metade {
    display: flex;
    height: 236px;
    a {
      width: 100%;
    }
  }

  @media (max-width: 1250px) {
    .metade {
      display: block;
      height: auto;
    }
  }
`

export const Bebida = styled.div`
  display: flex;
  justify-content: space-between;

  height: 100%;
  margin-right: 15px;
  padding: 0 45px 0 55px;
  border-radius: 6px;
  background-color: ${colors.ROXO_PASTEL};
  color: ${colors.ROXO_ESCURO};

  .text {
    padding-top: 35px;
    h1 {
      font-size: 49px;
      font-weight: 500;
      max-width: 315px;
    }

    p {
      padding-top: 20px;
      font-size: 21px;
      letter-spacing: 3px;
      font-weight: 500;
    }
  }
  .image {
    /* Alinha a imagem no fim */
    margin-top: auto;
    width: 140px;
  }

  @media (max-width: 1250px) {
    width: 100%;
    height: 236px;
    justify-content: space-between;
  }
  @media (max-width: 615px) {
    height: 142px;
    padding: 0 35px;

    .text {
      padding-top: 20px;
      width: 100%;
      max-width: 180px;

      h1 {
        font-size: 28px;
      }
      p {
        font-size: 12px;
      }
    }
    .image {
      max-width: 85px;
      width: 100%;
    }
  }
  @media (max-width: 425px) {
    padding-right: 25px;
  }
  @media (max-width: 320px) {
    height: auto;
    .text {
      padding-bottom: 10px;
    }
  }
`

export const Gomas = styled.div`
  display: flex;

  padding: 35px 0 35px 55px;
  border-radius: 6px;
  background-color: ${colors.VERDE_CLARO};
  color: ${colors.VERDE_ESCURO};

  .text {
    h1 {
      font-size: 49px;
      font-weight: 500;
    }

    p {
      padding-top: 20px;
      font-size: 21px;
      letter-spacing: 3px;
      font-weight: 500;
    }
  }

  .image {
    margin: auto 0;
    margin-right: 60px;
    width: 250px;
  }

  @media (max-width: 1250px) {
    margin-top: 15px;
    width: 100%;
    height: 236px;
    justify-content: space-evenly;

    .text {
      max-width: 50%;
    }
    .image {
      width: 100%;
      max-width: 200px;
    }
  }

  @media (max-width: 615px) {
    height: 142px;
    padding: 0;
    justify-content: space-around;

    .text {
      padding-top: 20px;

      h1 {
        font-size: 28px;
      }
      p {
        font-size: 12px;
      }
    }
    .image {
      margin: auto 0;
      width: 120px;
    }
  }
`

export const Balas = styled.div`
  display: flex;
  align-items: center;
  height: 236px;
  border-radius: 6px;
  margin-top: 15px;
  padding: 35px 55px;
  background-color: ${colors.LARANJA_PASTEL};
  color: ${colors.LARANJA_ESCURO};

  .text {
    h1 {
      font-size: 49px;
      font-weight: 500;
    }
    .link {
      display: flex;
      align-items: center;
      padding-top: 17px;
      a {
        font-weight: 500;

        text-decoration: none;
        font-size: 21px;
        line-height: 24px;
        color: ${colors.LARANJA_ESCURO};
      }
      svg {
        margin-bottom: 8px;
        margin-left: 4px;
      }
    }
  }
  .image {
    width: 836px;
  }
  @media (max-width: 940px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    width: 100%;

    .image {
      margin-top: 30px;
      width: 100%;
    }
  }
  @media (max-width: 615px) {
    padding: 35px 25px;

    .text {
      h1 {
        font-size: 28px;
      }
    }
  }
`

export const ContatoSection = styled.section`
  margin: 130px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  h1 {
    padding-bottom: 20px;
    color: ${colors.VERDE_ESCURO};
    font-size: 54px;
  }
  & > p {
    padding-bottom: 20px;
    color: ${colors.CINZA_CONTATO_CARD};
    max-width: 580px;
    text-align: center;
    line-height: 28px;
  }

  @media (max-width: 615px) {
    margin: 50px 0;

    h1 {
      font-size: 36px;
    }
  }
`
