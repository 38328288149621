import React from "react"
import styled from "styled-components"

import { FaWhatsapp } from "react-icons/fa"
import { FaPhoneAlt } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { FiArrowUpRight } from "react-icons/fi"

import colors from "../../styles/colors"

const ContatoCards = () => (
  <CardsContainer>
    <Card>
      <div>
        <FaPhoneAlt size={20} />
        <p>Ligue para a Ardrak</p>
      </div>
      <a href="tel:6235469393" target="_blank" rel="noreferrer">
        62 3546-9393 <FiArrowUpRight size={18} />
      </a>
    </Card>
    <Card>
      <div>
        <FaWhatsapp size={22} />
        <p>Mensagem via Whatsapp</p>
      </div>
      <a href="https://wa.me/556292966223" target="_blank" rel="noreferrer">
        62 99296-6223 <FiArrowUpRight size={18} />
      </a>
    </Card>
    <Card>
      <div>
        <MdEmail size={25} />
        <p>Envie um e-mail</p>
      </div>
      <a href="mailto:vendas@ardrak.com.br" target="_blank" rel="noreferrer">
        vendas@ardrak.com.br <FiArrowUpRight size={18} />
      </a>
    </Card>
  </CardsContainer>
)

const CardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Card = styled.div`
  width: 100%;
  max-width: 380px;
  border: 2px solid #e2e2e2;
  border-radius: 14px;
  padding: 35px;
  margin: 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.CINZA_CONTATO_CARD};
    padding-bottom: 10px;
    svg {
      margin-bottom: 5px;
    }
  }
  p {
    padding-left: 10px;
    font-size: 20px;
  }
  a {
    display: flex;
    align-items: center;

    text-decoration: none;
    color: ${colors.VERDE_ESCURO};
    font-size: 22px;
    font-weight: 500;

    svg {
      margin-bottom: 9px;
      margin-left: 4px;
    }
    :hover {
      color: ${colors.VERDE};
    }
  }

  @media (max-width: 400px) {
    padding: 25px 30px;
    margin: 5px 0;
  }
`

export default ContatoCards
