import React from "react"
import styled from "styled-components"

import { FiArrowUpRight } from "react-icons/fi"

import colors from "../../styles/colors"

import PagueMenos from "./images/pague-menos.js"
import AlexFarma from "./images/alexfarma.js"
import DrogaRaia from "./images/raia.js"
import DrogariasPacheco from "./images/pacheco.js"
import Drogasil from "./images/drogasil.js"

const PrincipaisParceiros = () => (
  <Container>
    <h1>Principais parceiros</h1>
    <p>
      Disponíveis nas melhores farmácias e comércios do Brasil, os produtos
      Ardrak são referência em derivados de gengibre.
    </p>
    <a href="https://munddi.com/ardrak/insta" target="_blank" rel="noreferrer">
      ONDE ENCONTRAR <FiArrowUpRight size={22} />
    </a>
    <div className="parceiros">
      <div className="image">
        <PagueMenos />
      </div>
      <div className="image">
        <AlexFarma />
      </div>
      <div className="image">
        <DrogaRaia />
      </div>
      <div className="image">
        <DrogariasPacheco />
      </div>
      <div className="image">
        <Drogasil />
      </div>
    </div>
  </Container>
)

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 200px;
  margin-bottom: 200px;

  h1 {
    padding-bottom: 20px;
    color: ${colors.VERDE_ESCURO};
    font-size: 56px;
    text-align: center;
  }

  p {
    padding-bottom: 20px;
    color: #000000;
    max-width: 576px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
  }

  a {
    margin-bottom: 50px;
    padding: 15px 30px 8px 30px;
    background-color: ${colors.VERDE_CLARO};
    border-radius: 999px;

    color: ${colors.VERDE};
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;

    display: flex;
    align-items: center;

    svg {
      position: relative;
      top: -3px;
    }

    &:hover {
      background-color: ${colors.VERDE_ESCURO};
      color: #ffffff;
    }
  }

  div.parceiros {
    width: 100%;
    max-width: 982px;
    margin: 0 auto;

    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: center;
    align-items: center;

    div.image {
      width: 171px;
      padding: 0 10px;
    }
  }

  @media (max-width: 1000px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  @media (max-width: 615px) {
    h1 {
      font-size: 36px;
    }

    div.parceiros {
      div.image {
        width: 130px;
        padding: 5px;
      }
    }
  }
  @media (max-width: 440px) {
    div.parceiros {
      div.image {
        width: 90px;
      }
    }
  }
  @media (max-width: 360px) {
    h1 {
      font-size: 32px;
    }
  }
`

export default PrincipaisParceiros
